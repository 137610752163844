import { render, staticRenderFns } from "./BidderDetailsTable.vue?vue&type=template&id=2d2ad53b&scoped=true&"
import script from "./BidderDetailsTable.vue?vue&type=script&lang=js&"
export * from "./BidderDetailsTable.vue?vue&type=script&lang=js&"
import style0 from "./BidderDetailsTable.vue?vue&type=style&index=0&id=2d2ad53b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d2ad53b",
  null
  
)

export default component.exports